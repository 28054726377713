import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import Button from '../components/button'

const BlogPostItemContainer = styled.div`
  flex: 0 0 33%;
  padding: 1%;
  @media (max-width: 767px) {
    flex: 0 0 50%;
    padding-bottom: 4%;
  }
  @media (max-width: 585px) {
    flex: 0 0 100%;
  }
  h2 {
    font-size: calc(0.35vw + 0.9em);
    font-weight: 600;
    @media (max-width: 585px) {
      font-size: calc(0.35vw + 1.1em);
    }
  }
  p {
    color: #666;
  }
  .innerpadding {
    padding: 1em;
  }
`
const PublishDate = styled.p`
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 0.75em;
  letter-spacing: 0.25px;
  font-weight: 600;
`
const Heading = styled.h2`
  margin: 0;
  margin-bottom: 0.25em;
`
const Excerpt = styled.p`
  font-size: 0.95em;
`

const BlogPostItem = ({
  title,
  slug,
  image,
  publishDate,
  author,
  category,
  categorySlug,
  page = 'blog',
  excerpt,
  className,
}) => {
  return (
    <BlogPostItemContainer className={className}>
      <div className={'  bg-white'} style={{ backgroundColor: 'white' }}>
        <GatsbyImage
          image={getImage(image)}
          style={{ height: '220px' }}
          alt={title}
        />
        <div className={'innerpadding'}>
          <PublishDate>
            {publishDate} by {author}{' '}
            {category && (
              <>
                in{' '}
                <Link
                  to={'/blog/category/' + categorySlug + '/'}
                  title={'View all posts in ' + category}
                >
                  {category}
                </Link>
              </>
            )}
          </PublishDate>
          <Heading>{title}</Heading>

          {excerpt ? <Excerpt>{excerpt}</Excerpt> : null}

          <Button
            to={`/${page}/` + slug}
            color={'secondary'}
            title={'Read more about ' + title}
          >
            Read More
          </Button>
        </div>
      </div>
    </BlogPostItemContainer>
  )
}

export default BlogPostItem
