import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import BlogPostItem from '../components/blog-post-item'

import Breadcrumbs from '../components/breadcrumbs'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const BlogPage = (props) => (
  <StaticQuery
    query={graphql`
      query GetAllBlogPosts {
        allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              metaDescription
              author {
                name
              }
              category {
                name
                slug
              }
              publishDate(formatString: "MMMM Do, YYYY")
              tags
              heroImage {
                gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <Layout pageProps={props}>
            <div className="bg-white border-b border-gray-100">
              <nav
                className="flex container mx-auto px-4 py-3"
                aria-label="Breadcrumb"
              >
                <Breadcrumbs
                  links={[
                    { to: '/', label: 'Home' },
                    {
                      to: props.location.pathname,
                      label: `Blog`,
                    },
                  ]}
                />
              </nav>
            </div>
            <SEO
              title={
                'Blog - Residential Builder Project Ideas, Tips & News | HouseIdea'
              }
              description={
                'HouseIdea Blog Offers Project Ideas, Trends, Tips and More!'
              }
            />
            <Heading style={{ paddingTop: '1em' }}>
              <h1>HouseIdea Blog</h1>
              <p>
                Project ideas, trends, and tips for residential builders,
                remodelers, and architects serving the residential construction
                industry.
              </p>
            </Heading>
            <BlogPostsLayoutContainer className="container">
              {data.allContentfulBlogPost.edges.map((item, i) => (
                <BlogPostItem
                  key={i}
                  title={item.node.title}
                  slug={item.node.slug}
                  image={item.node.heroImage}
                  publishDate={item.node.publishDate}
                  category={item.node.category.name}
                  categorySlug={item.node.category.slug}
                  author={item.node.author.name}
                  excerpt={item.node.metaDescription}
                />
              ))}
            </BlogPostsLayoutContainer>
          </Layout>
        </>
      )
    }}
  />
)

export default BlogPage
